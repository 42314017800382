<template>
  <div id="loader-screen" class="w-screen h-screen top-0 absolute flex items-center justify-center shadow bg-black bg-opacity-10 z-100">
<!--    <div class="text-red-600">test</div>-->
    <div class="h-64 w-64 pl-20 pt-10">
      <svg viewBox="0 0 16 16" width="30px" height="16px"  version="1.1"
           xmlns="http://www.w3.org/2000/svg">
        <circle id="c1" cx="8" cy=8 r="8" fill="#29B6F6" />
        <circle id="c2" cx="14" cy=8 r="8" fill="#1e4154" />
      </svg>
<!--      <div class="txt-loading">LOADING</div>-->
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "listLoader",
  data() {
    return {
      interval: null
    }
  },
  mounted() {
    this.interval = setInterval(function () {
      let loader = document.getElementById('loader-screen')
      let h = window.scrollY
      loader.style.top = h + 'px';
    }, 100);

    var tl = gsap.timeline({repeat: -1})
    tl.set("#c1", {autoAlpha:.7})
        .to("#c1", .5, {scale: .2, x: "+=5", transformOrigin:"50% 50%"})
        .to("#c1", .5, {scale:1, x: "-=5",transformOrigin:"50% 50%"} );

//animate second circle
    var tl2 = gsap.timeline({repeat: -1})
    tl2.set("#c2", {autoAlpha:.7})
        .to("#c2", .5, {scale: .2, x: "-=5",transformOrigin:"50% 50%"})
        .to("#c2", .5, {scale:1, x: "+=5",transformOrigin:"50% 50%"} )

//split loading text
//     var split = new SplitText(".txt-loading", {type:"chars", position:"absolute"});
//     var tlsplit = gsap.timeline({repeat: -1})

//now animate each character
//     tlsplit.staggerFrom(split.chars, 1, { autoAlpha:0}, 0.07)
//         .staggerTo(split.chars, .5, { autoAlpha:0}, 0.05);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
}
</script>

<style scoped>
.txt-loading{
  color: #1e4154;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 5px;
  font-size: 20px;
  text-align: center;
  margin: 5px 0 0 -25px;
}
</style>