<template>
  <div class="h-full w--full rounded-xl">
    <div :class="`rounded-tr-xl rounded-tl-xl w-full ${data.benefit.fixed ? 'h-3/6' : 'h-3/6'} bg-gray-400 bg-cover`" :style="`background-image: url('${getBaseImg(data.benefit.pictures)}'); background-position: center;`"></div>

    <div style="background-color: rgb(233,238,251);" class="px-1 h-1/6 w-full flex justify-between" v-if="data.benefits_type!='fixed'">
      <div class="flex items-center justify-start">
        <div v-if="data.benefit.price || data.benefit.discount" class="grid grid-cols-1 items-center justify-start space-y-0">
          <p class="font-medium text-gray-400 line-through text-sm md:text-base" v-if="!data.benefit.fixed && data.benefit.price > 0 && data.benefit.discount">{{data.benefit.price_currency}}</p>
          <p class="font-medium mt-2 text-sm md:text-base" v-if="!data.benefit.nfc && !data.benefit.fixed && data.benefit.price > 0">{{data.benefit.price_discount_currency}}</p>
          <div v-else></div>
        </div>

        <div class="flex justify-center items-center ml-5" v-if="(!data.benefit.nfc && !Number(data.benefit.price)) || (data.benefit.nfc)">
          <font-awesome-icon v-if="!data.benefit.nfc && !Number(data.benefit.price)" icon="fa-solid fa-file-invoice-dollar" class="ml-2" />
          <font-awesome-icon v-if="data.benefit.nfc" icon="fa-solid fa-mobile-screen-button" class="ml-2" />
          <p v-if="data.benefit.nfc" class="ml-2">IN-STORE</p>
          <p v-if="!data.benefit.nfc && !Number(data.benefit.price)" class="ml-2 font-medium">INVOICE</p>
        </div>
      </div>
      <div class="flex w-1/3 justify-end text-gray-600">
        <heart-icon v-if="data.benefits_type !== 'fixed'" @click.stop="toggleFavorites(data.benefit.id)" :class="`transform scale-75 hover:scale-90 hover:text-red-600 ${userFavoriteIds.includes(data.benefit.id) ? 'text-red-600': ''} transition duration-200 ease-in-out`" />
        <shopping-cart-icon v-if="!data.benefit.fixed && !data.benefit.special_offer && data.benefit.price > 0" @click.stop="toggleCart(data.benefit.id)" :class="`transform scale-75 md:hover:scale-90  hover:text-base-500 ${userCartIds.includes(data.benefit.id) ? 'text-base-500': ''} transition duration-200 ease-in-out`" />
      </div>
    </div>

    <div :class="`flex items-start w-full h-2/6`">
      <div :class="`flex items-center space-x-2 p-2  ${data.benefit.fixed ? 'w-full justify-center' : 'w-10/12'}`">
        <p :class="`text-sm font-medium text-ellipsis ${data.benefit.fixed? 'text-left': 'text-left'}`">{{data.benefit.name}}</p>
      </div>

      <div v-if="data.benefit.discount" class="w-2/12 bg-base-600 text-white text-center font-medium p-2 rounded-bl-xl">-{{data.benefit.discount}}%</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {ShoppingCartIcon, HeartIcon} from "@heroicons/vue/solid";

export default {
  name: "benefitBox",
  props: ['data'],
  components: {
    ShoppingCartIcon,
    HeartIcon,
  },
  computed: {
    ...mapGetters('auth', {
      userFavoriteIds: 'userFavoriteIds',
      userCartIds: 'userCartIds'
    })
  },
  methods: {
    ...mapActions('auth', {
      toggleFavorites: 'toggleFavorites',
      toggleCart: 'toggleCart'
    }),
  }
}
</script>

<style scoped>

</style>