
<template>
  <section
      v-if="isOpen"
      class="fixed top-0 left-0 w-full h-screen z-50 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center overflow-hidden overflow-y-auto"
      @click="handleClose"
  >
    <div
        @click.stop=""
        :class="`${calculateSize(size)} max-h-85% mx-auto scale-in-animation ${wrapperClassNames}`"
        :style="style"
    >
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "HobCardModal",
  props: ['isOpen', 'wrapperClassNames', 'size', 'bodyClassNames', 'title', 'style'],
  methods: {
    handleClose() {
      this.$emit('onClose');
    },
    calculateSize(size = 'regular') {
      switch (size) {
        case 'small':
          return 'w-11/12 md:w-520';
        case 'regular':
          return 'w-11/12 md:w-620';
        case 'medium':
          return 'w-11/12 md:w-9/12 lg:w-820';
        case 'large':
          return 'w-11/12 md:w-10/12 lg:w-9/12';
        default:
          return 'w-11/12 md:w-6/12';
      }
    }
  }
}
</script>

<style scoped>

</style>