import axios from "@/config/http"

const state = () => ({
    dark_mode: false,
    loading: false,
    loadings: {},
    success: false,
    success_message: "",
    error: false,
    error_message: "",
    benefit_preview_type: "grid"
});

const mutations = {
    switch_benefit_preview_type(state, view=null) {
        console.log(view, 'view')
        if (view) {
            state.benefit_preview_type = view
        } else {
            state.benefit_preview_type = state.benefit_preview_type == "grid"? "list": "grid"
        }
    },
    set_success(state, message) {
       state.success = true
       state.success_message = message

       setTimeout(()=>{
           state.success = false
           state.success_message = ""
       }, 3000)
    },
    set_error(state, message) {
        state.error = true
        state.error_message = message

        setTimeout(()=>{
            state.error = false
            state.error_message = ""
        }, 3000)
    },
    set_loading(state, timestamp) {
        if(timestamp in state.loadings) {
            state.loadings[timestamp] = false
        } else {
            state.loadings[timestamp] = true
        }
    }
};

const actions = {
    async toggle_loading(ctx, date= null) {
        let timestamp = date;
        if(!timestamp) {
            timestamp = new Date().getTime();
        }
        ctx.commit('set_loading', timestamp);
        return timestamp
    },
};
const getters = {
    is_loading(state) {
        return Object.values(state.loadings).find(e=>e)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}