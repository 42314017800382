import axios from "../../config/http";

// initial state
const state = () => ({
    cart: []
})

// getters
const getters = {
    cart(state) {
        return state.cart
    },
    cartCount(state) {
        return state.cart.length
    },
    cartHasItems(state) {
        return state.cart.length > 0
    },
}

// actions
const actions = {

}

// mutations
const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}