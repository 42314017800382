<template>
  <div>
    <success-notification :show="$store.state.ui.success"/>
    <error-notification :show="$store.state.ui.error" :message="$store.state.ui.error_message"/>
    <slot></slot>
  </div>
</template>

<script>
import SuccessNotification from "@/components/ui/SuccessNotification";
import ErrorNotification from "@/components/ui/ErrorNotification";

export default {
  name: "guest",
  components: {
    ErrorNotification,
    SuccessNotification,
  }
}
</script>

<style scoped>

</style>