<template>
  <empty v-if="$route.name == 'Start'">
    <router-view/>
  </empty>
  <default-layout v-else-if="$route.meta.requiresAuth">
    <router-view/>
  </default-layout>
  <guest-layout v-else>
    <router-view/>
  </guest-layout>
</template>

<script>
import defaultLayout from './layouts/default'
import guestLayout from './layouts/guest'
import empty from "./layouts/empty";

export default {
  components: {defaultLayout, guestLayout, empty},
}
</script>

