<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" si class="fixed z-10 inset-0" @close="open = false">
      <div class="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-80 filter blur-sm transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div :class="`${modalSize} inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all -translate-y-10 sm:align-middle`">
            <div class="overflow-y-auto px-4">
              <slot/>
            </div>
            <div v-if="hasCancelButton" class="">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-base-600 text-base font-medium text-white hover:bg-base-700 focus:outline-none sm:col-start-2 sm:text-sm" @click="$emit('close')">
                {{cancelText}}
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, InformationCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
  },
  props: {
    hide_submit: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    hasCancelButton: {
      default: true,
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large'
    },
  },
  computed: {
    modalSize() {
      if (this.size === 'small') return 'w-11/12 lg:w-96';
      if (this.size === 'regular') return 'w-11/12 lg:w-500';
      if (this.size === 'medium') return 'w-11/12 lg:w-700';
      if (this.size === 'large') return 'w-11/12 lg:w-7/12';
      return 'w-7/12';
    },
  }
}
</script>

<style scoped>
.modal-slot {
  height: calc(100vh - 250px);
}
</style>