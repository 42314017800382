<template>

<!--<?xml version="1.0" encoding="utf-8"?>-->
<!-- Generator: Adobe Illustrator 22.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="50" height="50"  viewBox="500 0 400 400">

<path class="st0" d="M797.4,13.7V0H520v13.7h13.8c9.4,0,16.7,7.2,16.7,16.6v216.2c0,9.4-7.2,16.6-16.7,16.6H520v13.7h277.4V263
	h-11.6c-9.4,0-16.7-7.2-16.7-16.6V30.3c0-9.4,7.2-16.6,16.7-16.6C785.8,13.7,797.4,13.7,797.4,13.7z M611.2,247.2V142.7h50
	c31.9,0,57.9,25.9,57.9,57.6v5.8c0,31.7-26.1,57.6-57.9,57.6h-33.3C618.5,263,611.2,255.8,611.2,247.2z M661.2,129h-50V29.5
	c0-8.6,7.2-15.9,15.9-15.9h33.3c31.9,0,57.9,25.9,57.9,57.6C719.2,103,693.1,129,661.2,129z"/>
<!--<g-->
<!--  v-if="showText"-->
<!--  class="st1">-->
<!--	<path d="M87.1,418.6V503H67.5v-34.6H29.2V503H9.7v-84.4h19.5v33.3h38.3v-33.3H87.1z"/>-->
<!--	<path d="M124.3,498.8c-7-3.8-12.5-9-16.5-15.6c-4-6.6-6-14.1-6-22.4c0-8.3,2-15.7,6-22.4s9.5-11.8,16.5-15.6-->
<!--		c7-3.8,14.9-5.7,23.7-5.7c8.8,0,16.6,1.9,23.6,5.7c7,3.8,12.5,9,16.5,15.6c4,6.6,6,14.1,6,22.4c0,8.3-2,15.7-6,22.4-->
<!--		c-4,6.6-9.5,11.8-16.5,15.6c-7,3.8-14.9,5.7-23.6,5.7C139.2,504.4,131.3,502.5,124.3,498.8z M161.4,484.4c4-2.3,7.2-5.5,9.5-9.6-->
<!--		c2.3-4.1,3.4-8.8,3.4-14c0-5.2-1.1-9.9-3.4-14c-2.3-4.1-5.4-7.3-9.5-9.6c-4-2.3-8.5-3.4-13.5-3.4c-5,0-9.5,1.1-13.5,3.4-->
<!--		c-4,2.3-7.2,5.5-9.5,9.6c-2.3,4.1-3.4,8.8-3.4,14c0,5.2,1.1,9.9,3.4,14c2.3,4.1,5.4,7.3,9.5,9.6c4,2.3,8.5,3.4,13.5,3.4-->
<!--		C152.9,487.8,157.4,486.7,161.4,484.4z"/>-->
<!--	<path d="M218.1,494.4c-6.7-6.7-10.1-16.2-10.1-28.6v-47.2h19.5v46.5c0,15.1,6.3,22.7,18.8,22.7c6.1,0,10.8-1.8,14-5.5-->
<!--		c3.2-3.7,4.8-9.4,4.8-17.2v-46.5h19.3v47.2c0,12.4-3.4,21.9-10.1,28.6c-6.7,6.7-16.1,10-28.1,10-->
<!--		C234.2,504.4,224.8,501.1,218.1,494.4z"/>-->
<!--	<path d="M312.1,501.7c-6.2-1.8-11.2-4.2-15-7l6.6-14.7c3.6,2.7,7.9,4.8,12.9,6.4c5,1.6,10,2.4,14.9,2.4c5.5,0,9.6-0.8,12.3-2.5-->
<!--		c2.7-1.6,4-3.8,4-6.6c0-2-0.8-3.7-2.3-5c-1.6-1.3-3.6-2.4-6-3.2c-2.5-0.8-5.8-1.7-9.9-2.7c-6.4-1.5-11.7-3.1-15.8-4.6-->
<!--		c-4.1-1.5-7.6-4-10.5-7.4c-2.9-3.4-4.4-7.9-4.4-13.5c0-4.9,1.3-9.3,4-13.3c2.7-4,6.6-7.1,12-9.5c5.3-2.3,11.9-3.5,19.6-3.5-->
<!--		c5.4,0,10.6,0.6,15.8,1.9c5.1,1.3,9.6,3.1,13.5,5.5l-6,14.8c-7.8-4.4-15.6-6.6-23.4-6.6c-5.5,0-9.5,0.9-12.1,2.7-->
<!--		c-2.6,1.8-3.9,4.1-3.9,7s1.5,5,4.5,6.4c3,1.4,7.6,2.8,13.8,4.2c6.4,1.5,11.7,3.1,15.8,4.6c4.1,1.5,7.6,3.9,10.5,7.2-->
<!--		c2.9,3.3,4.4,7.8,4.4,13.4c0,4.8-1.3,9.2-4,13.2c-2.7,4-6.7,7.1-12.1,9.5c-5.4,2.3-11.9,3.5-19.6,3.5-->
<!--		C324.8,504.4,318.4,503.5,312.1,501.7z"/>-->
<!--	<path d="M446,487.3V503h-65.3v-84.4h63.8v15.7H400v18.3h39.2v15.2H400v19.5H446z"/>-->
<!--	<path d="M512.8,498.8c-7-3.8-12.5-9-16.5-15.6c-4-6.6-6-14.1-6-22.4c0-8.3,2-15.7,6-22.4s9.5-11.8,16.5-15.6-->
<!--		c7-3.8,14.9-5.7,23.7-5.7c8.8,0,16.6,1.9,23.6,5.7c7,3.8,12.5,9,16.5,15.6s6,14.1,6,22.4c0,8.3-2,15.7-6,22.4-->
<!--		c-4,6.6-9.5,11.8-16.5,15.6c-7,3.8-14.9,5.7-23.6,5.7C527.7,504.4,519.8,502.5,512.8,498.8z M550,484.4c4-2.3,7.2-5.5,9.5-9.6-->
<!--		c2.3-4.1,3.4-8.8,3.4-14c0-5.2-1.1-9.9-3.4-14c-2.3-4.1-5.4-7.3-9.5-9.6c-4-2.3-8.5-3.4-13.5-3.4c-5,0-9.5,1.1-13.5,3.4-->
<!--		c-4,2.3-7.2,5.5-9.5,9.6c-2.3,4.1-3.4,8.8-3.4,14c0,5.2,1.1,9.9,3.4,14c2.3,4.1,5.4,7.3,9.5,9.6c4,2.3,8.5,3.4,13.5,3.4-->
<!--		C541.5,487.8,545.9,486.7,550,484.4z"/>-->
<!--	<path d="M616.9,434.3v22.3h39v15.7h-39V503h-19.5v-84.4h63.8v15.7H616.9z"/>-->
<!--	<path d="M781.7,466.8c2.7,3.6,4.1,8,4.1,13.2c0,7.4-2.9,13.1-8.6,17.1c-5.7,4-14.1,6-25.1,6h-43.6v-84.4h41.2-->
<!--		c10.3,0,18.2,2,23.7,5.9c5.5,3.9,8.3,9.3,8.3,16c0,4.1-1,7.8-3,11c-2,3.2-4.7,5.7-8.3,7.6C775.2,460.6,779,463.2,781.7,466.8z-->
<!--		 M727.8,433.3v19.9h19.4c4.8,0,8.5-0.8,11-2.5c2.5-1.7,3.7-4.2,3.7-7.5c0-3.3-1.2-5.8-3.7-7.4c-2.5-1.6-6.1-2.5-11-2.5H727.8z-->
<!--		 M762.3,485.8c2.6-1.7,3.9-4.3,3.9-7.8c0-7-5.2-10.5-15.5-10.5h-22.8v20.8h22.8C755.8,488.3,759.6,487.4,762.3,485.8z"/>-->
<!--	<path d="M866,487.3V503h-65.3v-84.4h63.8v15.7H820v18.3h39.2v15.2H820v19.5H866z"/>-->
<!--	<path d="M958.9,418.6V503h-16l-42.1-51.2V503h-19.3v-84.4h16.1l41.9,51.2v-51.2H958.9z"/>-->
<!--	<path d="M1044.2,487.3V503h-65.3v-84.4h63.8v15.7h-44.3v18.3h39.2v15.2h-39.2v19.5H1044.2z"/>-->
<!--	<path d="M1079.3,434.3v22.3h39v15.7h-39V503h-19.5v-84.4h63.8v15.7H1079.3z"/>-->
<!--	<path d="M1136.8,418.6h19.5V503h-19.5V418.6z"/>-->
<!--	<path d="M1193.8,434.5h-27v-15.9h73.5v15.9h-27V503h-19.5V434.5z"/>-->
<!--	<path d="M1258.5,501.7c-6.2-1.8-11.2-4.2-15-7l6.6-14.7c3.6,2.7,7.9,4.8,12.9,6.4c5,1.6,10,2.4,14.9,2.4c5.5,0,9.6-0.8,12.3-2.5-->
<!--		c2.7-1.6,4-3.8,4-6.6c0-2-0.8-3.7-2.3-5c-1.6-1.3-3.6-2.4-6-3.2c-2.5-0.8-5.8-1.7-9.9-2.7c-6.4-1.5-11.7-3.1-15.8-4.6-->
<!--		c-4.1-1.5-7.6-4-10.5-7.4c-2.9-3.4-4.4-7.9-4.4-13.5c0-4.9,1.3-9.3,4-13.3c2.7-4,6.6-7.1,12-9.5c5.3-2.3,11.9-3.5,19.6-3.5-->
<!--		c5.4,0,10.6,0.6,15.8,1.9c5.1,1.3,9.6,3.1,13.5,5.5l-6,14.8c-7.8-4.4-15.6-6.6-23.4-6.6c-5.5,0-9.5,0.9-12.1,2.7-->
<!--		c-2.6,1.8-3.9,4.1-3.9,7s1.5,5,4.5,6.4c3,1.4,7.6,2.8,13.8,4.2c6.4,1.5,11.7,3.1,15.8,4.6c4.1,1.5,7.6,3.9,10.5,7.2-->
<!--		c2.9,3.3,4.4,7.8,4.4,13.4c0,4.8-1.3,9.2-4,13.2c-2.7,4-6.7,7.1-12.1,9.5c-5.4,2.3-11.9,3.5-19.6,3.5-->
<!--		C1271.2,504.4,1264.8,503.5,1258.5,501.7z"/>-->
<!--</g>-->
<rect
  v-if="showText"
  x="520" y="647.6" class="st0" width="277.4" height="13.7"/>
</svg>

</template>

<script>
export default {
name: "NewLogo",
  props: [
    "showText"
  ]
}
</script>

<style scoped type="text/css">
	.st0{fill:#086D9D;}
	.st1{enable-background:new    ;}
</style>
