import axios from "../../config/http";

// initial state
const state = () => ({
    courses: null,
    coupons: null,
    packages: null,
    modalFormData: {},
    questionsBuffer: []
})

// getters
const getters = {
    courses(state) {
        return state.courses
    },
    coupons(state) {
        return state.coupons
    }
}

// actions
const actions = {
    async fetchCourses(context, action='') {
        let courses = await axios.get(`service/course/?ordering=-pk&course_type=${action}`)
        context.commit('setCourses', courses.data)
    },
    async fetchPackages(context, action='') {
        let res = await axios.get(`service/package/?ordering=-pk`)
        context.commit('setPackages', res.data)
    },
    async publishNewCourse(context) {
        let data = new FormData()

        for (const contextKey in context.state.modalFormData) {
            if(contextKey=='questions') {
                data.append('questions_string', JSON.stringify(context.state.modalFormData[contextKey]))
            } else if (!['pdf', 'certificate_pdf', 'pk', 'image'].includes(contextKey)) {
                data.append(contextKey, context.state.modalFormData[contextKey])
            }
            else if (['pdf', 'certificate_pdf', 'image'].includes(contextKey)) {
                if (context.state.modalFormData[contextKey] instanceof File) {
                    data.append(contextKey, context.state.modalFormData[contextKey])
                }
            }
        }

        let res = await axios.post('service/course/', data)
        context.commit('addCourse', res.data)
        context.commit('setModalFormData', {})
    },
    async publishNewPackage(context) {
        let data = new FormData()

        for (const contextKey in context.state.modalFormData) {
            if(contextKey=='courses') {
                data.append('courses', JSON.stringify(context.state.modalFormData[contextKey]))
            } else if (!['pdf', 'certificate_pdf', 'pk', 'image'].includes(contextKey)) {
                data.append(contextKey, context.state.modalFormData[contextKey])
            }
            else if (['pdf', 'certificate_pdf', 'image'].includes(contextKey)) {
                if (context.state.modalFormData[contextKey] instanceof File) {
                    data.append(contextKey, context.state.modalFormData[contextKey])
                }
            }
        }
        console.log(data)
        let res = await axios.post('service/package/', data)
        context.commit('addCourse', res.data)
        context.commit('setModalFormData', {})
    },
    async editCourseRequest(context) {
        let data = new FormData()
        for (const contextKey in context.state.modalFormData) {
            if(contextKey=='questions') {
                data.append('questions_string', JSON.stringify(context.state.modalFormData[contextKey]))
            } else if (!['pdf', 'certificate_pdf', 'pk', 'image'].includes(contextKey)) {
                data.append(contextKey, context.state.modalFormData[contextKey])
            }
            else if (['pdf', 'certificate_pdf', 'image'].includes(contextKey)) {
                if (context.state.modalFormData[contextKey] instanceof File) {
                    data.append(contextKey, context.state.modalFormData[contextKey])
                }
            }
        }

        let res = await axios.patch(`service/course/${context.state.modalFormData.pk}/`, data)
        context.commit('editCourse', res.data)
        context.commit('setModalFormData', {})
        setTimeout(()=>{
            context.dispatch('fetchCourses')
        }, 500)
    },
    async editPackageRequest(context) {
        let data = new FormData()
        for (const contextKey in context.state.modalFormData) {
            if(contextKey=='courses') {
                data.append('courses', JSON.stringify(context.state.modalFormData[contextKey]))
            } else if (!['pdf', 'certificate_pdf', 'pk', 'image'].includes(contextKey)) {
                data.append(contextKey, context.state.modalFormData[contextKey])
            }
            else if (['pdf', 'certificate_pdf', 'image'].includes(contextKey)) {
                if (context.state.modalFormData[contextKey] instanceof File) {
                    data.append(contextKey, context.state.modalFormData[contextKey])
                }
            }
        }

        let res = await axios.patch(`service/package/${context.state.modalFormData.pk}/`, data)
        context.commit('setModalFormData', {})
        setTimeout(()=>{
            context.dispatch('fetchPackages')
        }, 500)
    },
    async fetchCoupons(context) {
        let {data} = await axios.get('user/coupon/?ordering=-pk')
        context.commit('setCoupons', data)
    },
    async publishNewCoupon(context) {

        let res = await axios.post('user/coupon/', context.state.modalFormData)
        await context.dispatch('fetchCoupons')
        context.commit('setModalFormData', {})
    },
    async editCouponRequest(context) {
        let res = await axios.patch(`user/coupon/${context.state.modalFormData.pk}/`, {
            name: context.state.modalFormData.name,
            percent_off: context.state.modalFormData.percent_off
        })
        await context.dispatch('fetchCoupons')
        context.commit('setModalFormData', {})
    }
}

// mutations
const mutations = {
    copyToQuestionsBuffer(state, questions) {
      state.questionsBuffer = questions
    },
    setCourses(state, courses) {
        state.courses = courses
    },
    setPackages(state, packages) {
        state.packages = packages
    },
    setCoupons(state, coupons) {
      state.coupons = coupons
    },
    setModalFormData(state, data) {
        state.modalFormData = {...state.modalFormData, ...data}
    },
    addCourse(state, course) {
        state.courses.push(course)
    },
    addNewQuestion(state, questionIndex) {
        let new_questions = [...state.modalFormData.questions]
        if (new_questions.length - 1 == questionIndex) {
            new_questions.push({question: '', answers: [{text: '', correct: false}]})
        }
        else {
            new_questions.splice(questionIndex + 1, 0, {question: '', answers: [{text: '', correct: false}]})
        }
        state.modalFormData.questions = new_questions
    },
    deleteAnswer(state, position) {
        let new_questions = [...state.modalFormData.questions]
        new_questions[position[0]].answers.splice(position[1], 1)
        state.modalFormData.questions = new_questions
    },
    deleteQuestion(state, position) {
        let new_questions = [...state.modalFormData.questions]
        new_questions.splice(position, 1)
        state.modalFormData.questions = new_questions
    },
    addNewAnswer(state, questionIndex) {
        state.modalFormData.questions[questionIndex].answers = [...state.modalFormData.questions[questionIndex].answers, {text: '', correct: false}]
    },
    toggleAnswer(state, positions) {
        state.modalFormData.questions[positions[0]].answers[positions[1]].correct = !state.modalFormData.questions[positions[0]].answers[positions[1]].correct
    },
    editCourse(state, course) {
        console.log(course.pk, state.courses.map(e => {
            console.log(e.pk, course.pk)
            if (e.pk == course.pk) {
                console.log("ovaj je", course)
                return course
            }
            return e
        }))
        state.courses = state.courses.map(e => {
            if (e.pk == course.pk) {
                return course
            }
            return e
        })
    },
    clearModalFormData(state) {
        state.modalFormData = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}