<template>
  <div>
      <success-notification :show="$store.state.ui.success"/>
      <error-notification :show="$store.state.ui.error" :message="$store.state.ui.error_message"/>
      <slot></slot>
  </div>
</template>

<script>
import {Popover, PopoverButton, PopoverGroup, PopoverPanel} from '@headlessui/vue'
import {mapGetters, mapActions} from 'vuex'
import footerComponent from '../components/footer'
import SuccessNotification from "@/components/ui/SuccessNotification";
import ErrorNotification from "@/components/ui/ErrorNotification";

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorClickIcon,
  },
  {name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon},
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: ViewGridIcon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will drive your customers to convert',
    href: '#',
    icon: RefreshIcon,
  },
]
const callsToAction = [
  {name: 'Watch Demo', href: '#', icon: PlayIcon},
  {name: 'Contact Sales', href: '#', icon: PhoneIcon},
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description: 'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description: 'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  {name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon},
]
const recentPosts = [
  {id: 1, name: 'Boost your conversion rate', href: '#'},
  {id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#'},
  {id: 3, name: 'Improve your customer experience', href: '#'},
]
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  LogoutIcon,
  LoginIcon,
  XIcon,
    PaperClipIcon
} from '@heroicons/vue/outline'
import {ChevronDownIcon, AcademicCapIcon, UserIcon, ShoppingCartIcon} from '@heroicons/vue/solid'

export default {
  components: {
    ErrorNotification,
    SuccessNotification,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
    UserIcon,
    ShoppingCartIcon,
    footerComponent,
    LogoutIcon,
    LoginIcon,
    PaperClipIcon,
  },
  data() {
    return {
      menu: {
        selected: '',
        items: []
      }
    }
  },
  setup() {
    return {
      solutions,
      callsToAction,
      resources,
      recentPosts,
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuth', 'user', 'token']),
    ...mapGetters('cart', ['cartCount', 'cartHasItems'])
  },
  methods: {
    ...mapActions('auth', ['fetchUser', 'checkAuth'])
  },
  beforeMount() {
    this.checkAuth()
  },
  async mounted() {
    if (!this.isAuth && !this.token) {
      await this.$router.push('/login')
    } else if (!this.isAuth && this.token) {
      console.log(this.axios.defaults.he)
      await this.fetchUser()
    }
    let studentNav = [{
      title: "Courses",
      route: '/packages',
      sub_items: [
        {title: 'Packages', route: "/packages", description: "All packages", icon: PaperClipIcon},
          {
        title: 'Initial',
        route: "/initial",
        description: "Not passed courses",
        icon: AcademicCapIcon
      }, {title: 'Continuation', route: "/continuation", description: "Refresh your license", icon: PlayIcon}],
    },
      {
        title: "Account",
        route: '/account'
      },
      {
        title: "About",
        route: '/about'
      },
      {
        title: "Contact",
        route: '/contact'
      },
      {
        title: "FAQ",
        route: '/faq'
      }]
    console.log(this.user.groups)
    if (this.user.groups.find(e => e.name == 'user')) {
      this.menu.items.push(...studentNav)
    }
    if (this.user.groups.find(e => e.name == 'admin')) {
      this.menu.items.push({title: 'Admin panel', route: '/admin-panel'})
    }
  }
}
</script>
