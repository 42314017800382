<template>
  <div v-if="totalPages > 1" class="flex items-center justify-end mt-3 mb-16 lg:mb-0 font-medium text-sm">
    <div class="w-10 h-10 bg-white shadow rounded-full flex items-center justify-center">
      {{ page }}
    </div>
    <div class="w-10 h-10 rounded-full flex items-center justify-center text-gray-600">
      od {{ totalPages }}
    </div>
    <button class="w-10 h-10 rounded-full bg-white hover:bg-gray-50 shadow font-medium" @click="previousPage" :disabled="page === 1 || disabled">
      <font-awesome-icon  icon="fa-chevron-left" />
    </button>
<!--    <span class="font-medium">{{ page }} / {{ totalPages }}</span>-->
    <button class="w-10 h-10 rounded-full bg-white hover:bg-gray-50  shadow font-medium" @click="nextPage" :disabled="page === totalPages || disabled">
      <font-awesome-icon  icon="fa-chevron-right" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      totalPages: 1,
    }
  },
  props: {
    totalItems: {
      required: true,
      default: 20
    },
    disabled: {
      required: true,
      default: false,
    },
    perPage: {
      required: true,
      default: 10,
    }
  },
  mounted() {
    this.totalPages = Math.round(this.totalItems / this.perPage);
  },
  watch: {
    totalItems(val) {
      if (val < 10) {
        this.totalPages = 1;
      } else {
        this.totalPages = Math.round(val / this.perPage);
      }
    },
  },
  methods: {
    previousPage() {
      if (this.page > 1) {
        this.page--;
        this.$emit('onPrevious');
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.$emit('onNext');
      }
    },
  },
};
</script>

<style scoped>
/* Prilagodite stilove za paginaciju prema potrebi */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  margin: 0 8px;
}
</style>