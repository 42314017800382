<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-50 inset-0 h-screen" @close="open = false">
      <div class="flex items-end justify-center  pt-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out dur<=ation-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen " aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div :class="`${modalSize} inline-block align-bottom bg-white pt-5 pb-3 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle mt-10`">
            <h2 v-if="title && title.length" class="text-black font-medium text-lg pb-4 px-2 md:px-4 border-b">{{ title }}</h2>
            <div class="overflow-y-auto modal-slot py-5">
              <slot/>
            </div>
            <div class="px-5 py-1 flex items-center justify-end gap-x-5">
              <button type="button" class="w-full inline-flex justify-center border border-gray-300 rounded-md bg-white text-base shadow-sm px-4 py-2 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:col-start-2 sm:text-sm" @click="$emit('close')">
                {{cancelText}}
              </button>
              <button v-if="!hide_submit" type="button" class="w-full inline-flex justify-center border border-transparent rounded-md border shadow-sm px-4 py-2 bg-base-600 text-base text-white hover:bg-base-700  font-medium sm:mt-0 sm:col-start-1 sm:text-sm" @click="$emit('submit')">
                <div v-if="loading">
                  <div style="border-top-color:transparent"
                       class="w-6 h-6 border-4 border-blue-400 border-double rounded-full animate-spin"></div>
                </div>
                <span v-else>{{submitText}}</span>
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, InformationCircleIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
  },
  props: {
    hide_submit: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
    },
    submitText: {
      type: String,
      default: 'Submit'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large'
    }
  },
  computed: {
    modalSize() {
      if (this.size === 'small') return 'w-11/12 lg:w-96';
      if (this.size === 'regular') return 'w-11/12 lg:w-500';
      if (this.size === 'large') return 'w-11/12 lg:w-7/12';
      return 'w-7/12';
    },
  }
}
</script>

<style scoped>
.modal-slot {
  max-height: calc(100vh - 250px);
}
</style>