import {mapGetters, mapMutations} from "vuex"
import locale from "../locale/translates"
import vClickOutside from 'click-outside-vue3'


export default {
    data() {
        return {
            message: 'hello',
            foo: 'abc'
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapGetters("auth", {
            language: "language",
            languageHelper: 'getLanguageHelper',
        }),
        loc() {
            if (locale[this.language]) {
                return locale[this.language]
            } else if (!this.language && this.languageHelper) {
                return locale[this.languageHelper]
            }
            return locale.EN;
        }
    },
    methods: {
        ...mapMutations('ui', {
            set_error: 'set_error',
            set_success: 'set_success'
        }),
        monetary(value, currency_code) {
            if (value === "") {
                return ""
            }
            let currency = 'RSD'
            if (currency_code) {
                currency = currency_code
            }
            // Todo: old implementation, didnt work for both safari and chrome
            // const formated = new Intl.NumberFormat('en-DE', { style: 'currency', currency: currency == "KM" ? "BAM" : currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
            // let currency_split = currency == "EUR" ? "€" : currency == "KM" ? "BAM" : currency;
            let formated = new Intl.NumberFormat('en-DE', { style: 'currency', currency: currency == "KM" ? "BAM" : currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
            if(currency === 'EUR') {
              formated = formated.replace('€', 'EUR ')
            }
            if(currency === 'BAM') {
              formated = formated.replace('BAM', 'KM')
            }
            return formated;
            
            // return formated.split(currency_split)[1] + ' ' + currency;
        },
        parse_name(name) {
            let srb = '';
            let eng = '';
            if (name.includes('/')) {
                eng = name.split('/')[0];
                srb = name.split('/')[1];
                if (this.$store.state.auth.user.language == "SR") {
                    return srb;
                } else {
                    return eng;
                }
            }
            return name;
        },
        getBaseImg(images) {
            if (!images || !images.length) {
                return null
            }

            function compare( a, b ) {
                if ( a.position < b.position ){
                    return -1;
                }
                if ( a.position > b.position ){
                    return 1;
                }
                return 0;
            }

            return images.sort( compare )[0].image;
        }
    }
}