<template>
  <div>
    <div
      v-if="notifications.length > 0"
      v-for="n in notifications"
      @click="$emit('onNotificationClick', n)"
      class="w-full flex items-center gap-x-1 bg-gray-50 mb-4 rounded-md group p-2 cursor-pointer hover:bg-gray-50 transition duration-200 ease-in-out">
      <div class="w-16 flex items-center justify-center">
        <font-awesome-icon icon="fa-solid fa-message" :class="`${n.previewed ? 'text-gray-400' : 'text-base-600'} ${isSingleNotification ? 'text-base-600' : ''} group-hover:text-base-600 h-8 w-8 mt-1 animation duration-200 ease-in-out`" />
      </div>
      <div v-if="!isSingleNotification" class="w-3/4 group-hover:text-base-600 font-medium animation duration-200 ease-in-out" :class="`${n.previewed ? 'text-gray-400' : 'text-base-600'}`">
        <p>{{ n?.text }}</p>
        <div class="mt-1">
          <p>{{ n?.date }}</p>
        </div>
      </div>
      <div v-if="isSingleNotification" class="w-3/4 font-medium animation duration-200 ease-in-out">
        <p>{{ n?.text }}</p>
        <div class="mt-1">
          <p>{{ n?.date }}</p>
        </div>
      </div>
    </div>
    <div v-if="notifications.length === 0" class="font-medium text-gray-600 h-24 flex items-center justify-center">{{ loc.there_are_no_notifications }}</div>
  </div>
</template>

<script>
export default {
  name: "notifications-modal",
  props: ['notifications', 'isSingleNotification'],
}
</script>

<style scoped>

</style>