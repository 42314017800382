<template>
  <div>
    <div class="border-b w-full pb-7 sticky top-0 bg-white z-20 border transform -translate-y-6 shadow-md overflow-hidden">
      <div class="w-full md:w-1/2 lg:w-1/3 mx-4 lg:ml-6 relative flex items-center pt-3">
        <div class="absolute inset-y-3 left-0 flex py-2 pl-1.5">
          <search-icon class="text-gray-500 h-5 w-5"/>
        </div>
        <div class="w-11/12 lg:w-full shadow-sm focus:ring-base-500 focus:border-base-500 block sm:text-sm border-gray-300 rounded-md">
          <input v-model.lazy="search" v-debounce="400" type="text" name="search" id="search" :placeholder="loc.search_by_provider_name"
                 class="w-full shadow-sm focus:ring-base-500 focus:border-base-500 block pr-12 sm:text-sm border-gray-300 rounded-md pl-9"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grid-flow-row ">
      <div v-for="provider in providersToShow" class="flex flex-col justify-start items-center p-3 lg:p-5">
        <div class="rounded-full bg-base-600 w-5 h-5 flex justify-center items-center text-white">{{provider.num_of_benefits}}</div>
        <div
          @click="toggle_selected_providers(provider.id)"
          :class="`relative cursor-pointer bg-gray-400 rounded-full w-14 h-14 ${selected_providers.includes(provider.id) ? 'border-4 shadow-xl shadow-base-700' : ''} border-base-600`">
          <img class="rounded-full" v-if="provider.picture" :src="provider.picture">
        </div>
        <p class="text-sm text-center text-gray-600">{{provider.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {SearchIcon} from "@heroicons/vue/solid"

export default {
  name: "providersSelect",
  data() {
    return {
      search: '',
    }
  },
  components: {
    SearchIcon,
  },
  computed: {
    ...mapState('filters', ['selected_category_providers']),
    ...mapState('benefits', ['selected_providers']),
    providersToShow() {
      return this.selected_category_providers.filter((p) => p.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    ...mapMutations('benefits', ['toggle_selected_providers'])
  }
}
</script>

<style scoped>
img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}
</style>